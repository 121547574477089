import './index.scss'
import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { B2bSubmitButton } from '../../pages/b2b'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

export const WeWillHelpSection = styled(({ className }) => {
  const wWidth = useSelector(selectDeviceSize)

  return (
    <section className={cn('we-will-help', className)}>
      <div className="container">
        <div className="row">
          <div className="designer-col col-12">
            <h2 className="we-will-help__title h1">Поможем с&nbsp;выбором</h2>
            <img
              alt="Девушка и мужчина разговаривают"
              className="we-will-help__img"
              src="/img/we-will-help/decor.png"
              srcSet="/img/we-will-help/decor@2x.png 2x, /img/we-will-help/decor@3x.png 3x"
            />
            <p className="we-will-help__desc">
              Оставьте свои данные, чтобы мы связались с&nbsp;вами
              и&nbsp;помогли выбрать формат поддержки для&nbsp;вашей команды.
            </p>
            {B2bSubmitButton('choice', 'we-will-help__btn')}
          </div>
        </div>
      </div>
      {wWidth > 767 && (
        <img
          alt="Светло-красный полукруг"
          className="we-will-help__decor"
          src="/img/we-will-help/decor0.svg"
        />
      )}
    </section>
  )
})``
