import React from 'react'
import Swiper from 'react-id-swiper'

const params = {
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  preventClicks: false,
  preventClicksPropagation: false
}

const commentsList = [
  {
    author: 'Галина Светская',
    avatar: '/img/comments/new-ava.png',
    imgAlt: 'Фотография счастливо улыбающейся девушки',
    description: 'Менеджер по корпоративной культуре Nexters',
    reviewTitle: 'Позитивные отзывы коллег',
    Review: () => (
      <>
        Для нас, как ИТ-компании, важно заботиться о&nbsp;сотрудниках
        и&nbsp;предлагать им условия, соответствующие самым высоким стандартам.
        С&nbsp;апреля 2020 мы запустили программу психологической поддержки.
        Когда выбирали провайдера, в&nbsp;пользу YouTalk сыграли такие факторы,
        как прозрачное ценообразование и&nbsp;широкая линейка форматов общения
        с&nbsp;психологами. Программа идёт хорошо, мы&nbsp;видим это по
        повторным обращениям и&nbsp;позитивным отзывам коллег.
      </>
    )
  },
  {
    author: 'Валерий Ильинский',
    avatar: '/img/comments/new-ava2.png',
    imgAlt: 'Портретная фотография мужчины',
    description:
      'Генеральный директор, сооснователь Медико-генетического центра Genotek',
    reviewTitle: 'Уровень стресса в компании снизился',
    Review: () => (
      <>
        Программа оказалась крайне эффективной: почти 40% сотрудников
        воспользовались возможностью пообщаться с&nbsp;психологом один на один,
        все отзывы положительные, многие получили уже по&nbsp;несколько
        консультаций. Больше половины сотрудников приняли участие
        в&nbsp;групповых программах. Несмотря на&nbsp;рост числа заболевших
        в&nbsp;течение осени и&nbsp;введение новых ограничительных мер, уровень
        стресса в&nbsp;компании снизился. Конфликтных ситуаций стало явно
        меньше.
      </>
    )
  },
  {
    author: 'Екатерина Рудчик',
    avatar: '/img/comments/new-ava3.png',
    imgAlt: 'Портретная фотография женщины',
    description: 'HR директор SETTERS',
    reviewTitle: 'Это было нужно, это было вовремя',
    Review: () => (
      <>
        Почти каждый сказал, что это было нужно, это&nbsp;было вовремя,
        и&nbsp;это уже комплексно отражается на&nbsp;их жизни, в&nbsp;том числе
        на&nbsp;работе. Стало проще сосредоточиться, выполнять задачи,
        относиться к&nbsp;каким-то задачам, к&nbsp;асапам.
      </>
    )
  }
]

const B2BCommentItem = ({
  author,
  avatar,
  imgAlt,
  description,
  reviewTitle,
  Review
}) => (
  <div className="b2b-comments-card">
    <div className="b2b-comments-card__head">
      <img alt={imgAlt} className="b2b-comments-card__ava" src={avatar} />
      <div className="b2b-comments-card__info">
        <p className="h5">{author}</p>
        <p>{description}</p>
      </div>
    </div>
    <p className="b2b-comments-card__title h5">{reviewTitle}</p>
    <p className="b2b-comments-card__content">
      <Review />
    </p>
  </div>
)

export const B2BCommentsList = ({ swiper }) =>
  swiper ? (
    <Swiper {...params}>
      {commentsList.map(
        ({ author, avatar, imgAlt, description, reviewTitle, Review }) => (
          <li key={author} className="b2b-comments-list__item">
            <B2BCommentItem
              Review={Review}
              avatar={avatar}
              imgAlt={imgAlt}
              description={description}
              reviewTitle={reviewTitle}
            />
          </li>
        )
      )}
    </Swiper>
  ) : (
    commentsList.map(({ author, avatar, description, reviewTitle, Review }) => (
      <li key={author} className="b2b-comments-list__item">
        <B2BCommentItem
          Review={Review}
          author={author}
          avatar={avatar}
          description={description}
          reviewTitle={reviewTitle}
        />
      </li>
    ))
  )
